
html {
  width: 100%;
}
.AppContainer{
    display: flex;
    min-height: calc(100vh - 80px);
    align-items: stretch;
  }

  .pagesContainer{
    width:80%;
  }