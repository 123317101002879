.reviewShipping {
    /* flex: 5; */
    padding: 40px;
    /* display: flex;
    flex-direction: column; */
}
.reviewShippingfooter{
    display: flex;
    justify-content: end;
}

.reviewShippingSubmitButton {
    margin-top: 20px !important;
    margin-right: 20px !important;
    width: 300px;
}