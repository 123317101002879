.deliveryNotes{
    flex: 5;
    padding: 10px 40px;
}

.deliveryNotesHeader {
    display: flex;
    justify-content: space-between;
    margin: 40px 40px 0px 40px;
    font-size: 1.5em;
    font-weight: 600;
    color:#009be5;
    padding-bottom: 28px;
}

.MuiButton-root  {
    padding: 15px !important;
    cursor: pointer !important;
}


.footer {
    text-align: right;
}