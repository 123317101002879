.shipperAddress {
    flex: 5;
    padding: 40px;
}

.shipperAddressHeader {
    display: flex;
    justify-content: space-between;
    margin: 40px 40px 0px 40px;
    font-size: 1.5em;
    font-weight: 600;
    color:#009be5;
    padding-bottom: 28px;
}

.shipperAddressFooter {
    display: flex;
    justify-content: flex-end;    
}

.shipperAddressSubmitButton {
    margin-right: 70px !important;
    margin-top: 20px !important;
    color: #009be5 !important;
}
    