.sidebar {
    width: 20%;
    min-height:  calc(100vh - 80px);
    /* position: sticky;
    top: 80px; */
    /* background-color: #24292e; */
    background-color: #2e3e4f;
    /* height: 100%; */
}

/* .sidebarWrapper {
    padding-left: 30px;
} */

.sidebarHeader1 {
    width: 100%;
    margin: 0;
    height: 60px;
    border-top: 1px solid rgb(167, 167, 167);
    background-color: #435160;
    display: flex;
    align-items: center;
}

.sidebarHeader1 span {
    color: rgb(232, 232, 232);
    font-size: 1.4em;
    font-weight: 300;
    margin-left: 55px;
}

.sidebarHeader2 {
    width: 100%;
    height: 50px;
    margin: 0;
    border-bottom: 1px solid rgb(167, 167, 167);
    background-color: #232f3e;
    display: flex;
    align-items: center;
}

.sidebarHeader2 span {
    color: #009be5;
    font-size: 1.1em;
}

.shippingIcon {
    color: rgb(255, 255, 255);
    margin-right: 10px;
    margin-left: 20px;
}


.sidebarMenu {
    border-bottom: 1px solid rgb(167, 167, 167);
}

.sidebarTitle {
    font-size: 20px;
    font-weight: 300;
    color: rgb(176, 176, 176);
    margin-top: 20px;
    margin-left: 20px;
}

.sidebarList {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    list-style: none;
    padding: 25px 0;
}   

.sidebarList a{
    color: rgb(223, 223, 223);
    padding: 5px 5px 5px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.selected,
.sidebarList a:hover {
    color: #009be5 !important;
    color: #ffa900 !important;
    background-color: rgb(53, 53, 53);
}

.sidebarIcon svg{
    margin-right: 5px;
    font-size: 20px !important;
}