.topbar{
    /* width: 100%; */
    height:80px;
    /* background-color: #24292e;
    background-color: #2e3e4f; */
    /* border-bottom: #2e3e4f 3px solid; */
    -webkit-box-shadow: -2px 1px 15px 5px rgba(0,0,0,0.3); 
    box-shadow: -2px 1px 15px 5px rgba(0,0,0,0.3);
}

.topbarWrapper{
    height: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
}

.topLeft {
    display: flex;
    justify-content: baseline;
    align-items: center;
}

.ca-logo {
    height: 80px;
    margin-right: 10px;
}

.logo{
    font-weight: 800;
    font-size: 28px;
    color: #2e3e4f;
    cursor: pointer;
    margin-left: 60px;
}
/* 
.topRight{
    display: flex;
    align-items: center;
}

.topbarIconContainer{
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
}

.topIconBadge{
    height: 15px;
    width: 15px;
    position: absolute;
    top: -8px;
    left: 15px;
    background-color: red;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topAvatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
} */