.firstPageWrapper, .firsPageMobile {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100vh;
    z-index: 3000;
    background-color: #2e3e4f;
}

.firsPageMobile {
    z-index: 7000;
    display: none;
    background-color: 2e3e4f;
}

.firsPageMobile h1{
    color: #ffa900;
    text-align: center;

}

.firsPageTitle {
    text-align: center;
}

.firsPageTitle h1{
    color: #fff;
}

.firsPageAZ-logo{
    margin-bottom: 50px;
    height: 80px;
}

.firsPageStartButton {
    margin-top: 50px !important;
    width: 100px !important;
    height: 30px !important;
    background-color: #ffa900 !important;
    cursor: pointer;
    font-weight: bold !important;
}

@media screen and (min-device-width: 767px) and (orientation: portrait) {
    .firsPageMobile {
        display: flex;
    }
    .tryMe{
        margin-top: 70px;
        color: #fff;
        font-size: 1.7em;
    }
}