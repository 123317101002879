.addressBook {
    min-height:  calc(100vh - 80px);
}

.addressBookHeader {
    height: 30px;
    background: #000;
    color: #fff;
    display: flex;
    align-items: center;
    
}

.headerIcon {
    margin: 5px 10px;
}

.addressBookHeader h3 {
    margin-top: 3px !important;
}

.addressBookContainer {
    display: flex;
}